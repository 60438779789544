<template>
  <div class="view">
    <ToolsList />
  </div>
</template>

<script>
import ToolsList from "./tools-list.vue";

export default {
  name: "Home",
  components: { ToolsList },
};
</script>

<style scoped>
.view {
  margin-top: 50px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
</style>