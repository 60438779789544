<template>
  <div>
    <div class="item-box" v-for="item in items" v-bind:key="item.path">
      <div class="status-text">{{ statusText(item) }}</div>

      <div class="item-box-inner">
        <div class="item-box-left">
          <router-link :to="item.path">
            <img :src="item.icon" />
          </router-link>
        </div>
        <div class="item-box-right">
          <div>
            <h3>
              <router-link :to="item.path"> {{ item.title }} </router-link>
            </h3>
          </div>
          <div>{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsList",

  data() {
    const basePath = "/xushifu";

    const list = [
      {
        path: basePath + "/qrcode",
        title: "二维码",
        icon: "/image/ic_qrcode.png",
        description: "生成二维码",
        status: "dev",
      },
      {
        path: basePath + "/datetime",
        title: "日期-时间",
        icon: "/image/ic_datetime.png",
        description: "日期-时间转换",
        status: "alpha",
      },
      {
        path: basePath + "/random",
        title: "随机数",
        icon: "/image/ic_random.png",
        description: "按指定的格式生成随机数值",
        status: "alpha",
      },
      {
        path: basePath + "/base64codec",
        title: "Base64",
        icon: "/image/ic_base64.png",
        description: "Base64 编码、解码",
        status: "alpha",
      },
      {
        path: basePath + "/cli-helper",
        title: "命令行助手",
        icon: "/image/ic_cli_helper.png",
        description: "命令行辅助工具",
        status: "dev",
      },
      {
        path: basePath + "/simple-hash",
        title: "简易 HASH 工具",
        icon: "/image/ic_simple_hash.png",
        description: "一个用来计算文本hash值的简易工具",
        status: "alpha",
      },
      {
        path: basePath + "/regexp",
        title: "正则表达式编辑器",
        icon: "/image/ic_regexp.png",
        description: "一个用来编写正则表达式的辅助工具",
        status: "dev",
      },
      {
        path: basePath + "/csv-formatter",
        title: "CSV格式化工具",
        icon: "/image/ic_csv_fmt.png",
        description: "把CSV表格格式化为指定的形式",
        status: "dev",
      },
    ];

    return {
      items: list,
    };
  },

  methods: {
    handleClickItem(item) {
      console.log("goto " + item.path);
      this.$router.push(item.path);
    },

    isVisible(item) {
      if (item == null) {
        return false;
      }
      return item.status == "hide";
    },

    statusText(item) {
      if (item == null) {
        return "null";
      }
      return item.status;
    },
  },
};
</script>


<style scoped>
.item-box {
  padding: 10px;
  margin: 10px;

  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);

  display: inline-block;
  width: 90%;
  max-width: 380px;
  min-height: 120px;

  text-align: left;
}

.item-box-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-box-left {
  padding: 10px;
  display: inline-block;
  flex-basis: 100px;
}

.item-box-right {
  padding: 10px;
  display: inline-block;
  flex-direction: column;
  align-items: initial;
  flex-grow: 1;
  text-align: left;
}

.status-text {
  text-align: right;
  color: red;
}
</style>
